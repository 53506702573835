@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;700&display=swap');

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.App {
  text-align: center;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; 
  background-color: #ffffff;
}

.header-top {
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: flex-start; /* Horizontally align to the left */
  padding-left: 2vw; /* Adjust as needed for spacing from the left edge */
  border-bottom:2px solid #E5E7EB;
}

.top-header {
  color: #1F477D;
}

.HomePage {
  text-align: left;
  margin-left: 10px;
  padding-top: 60px; /* Adjust as needed for spacing from the top edge */
}

.HomePage-header {
  padding: 20px;
  color: #000000;
}

.sub-header {
  color: #000000;
  margin-top: -15px;
}

.sub-text {
  color: #000000;
  margin-top: -10px;
  background-color: #f9f9f9;
  padding-bottom: 20px;
  border-radius: 10px;
}

.resources-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}

.resource-header {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  color: #1F477D;
  flex-direction: row;
}

.template-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  text-align: left;
}

.resource-item {
  width: 300px; /* Set a fixed width */
  height: 400px; /* Set a fixed height */
  background-color: #f9f9f9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.resource-image {
  width: 100%;
  height: auto;
  max-height: 250px;
  border-radius: 10px;
}

.resource-icon {
  padding: 20px;
}

.resource-title {
  font-size: 1.2em;
  margin: 10px;
  color: #1F477D;
}

.resource-description {
  font-size: 1em;
  margin: 10px;
  color: #1F477D;
}

.resource-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
}

.resource-link:hover {
  text-decoration: none; /* Ensure underline is not added on hover */
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust as needed */
  padding: 150px;
}

.email-button {
  background-color: #1F477D;
  color: white;
  border: none;
  padding: 40px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.button-icon {
  margin-right: 10px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 60px;
  border-radius: 5px;
  text-align: center;
}

.popup-content p {
  margin-bottom: 20px;
}

.popup-content button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
}

.chatbot-container {
  display: flex;
  flex-direction: column;
  height: 65vh; 
  width: 80%; 
  max-width: 80%;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
}

.chatbot-messages {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #f9f9f9;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
}

.message.user {
  background-color: #1F477D;
  align-self: flex-end;
  color: #fff;
}

.message.bot {
  background-color: #f8d7da;
  align-self: flex-start;
}

.chatbot-form {
  display: flex;
  padding: 10px;
  background-color: #f9f9f9;
}

.chatbot-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.chatbot-send-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #1F477D;
  color: #fff;
  cursor: pointer;
}

.chatbot-send-button:hover {
  background-color: #1F477D;
}

.sources-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.source-tile {
  background-color: #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  width: calc(30% - 8px); /* Ensure each tile is the same size */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Inherit text color */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.source-tile:hover {
  background-color: #d0d0d0; /* Change background color on hover */
}